<template>
<div class="d-flex align-items-center justify-content-center flex-column vh-100 " :class="{'verticallyUTM' :$route.query.utm_campaign.includes('728x90')}">
    <div class="bg-white position-relative fullWidthBanner" style="max-width: 508px;min-width: 270px; border-radius:8px">
        <div>
            <a href="javascript:void(0)" class="arrow-img left" @click="prev()">
                <v-lazy-image src="/assets/images/left-arrow.png" alt="Logo" title="left-arrow" />
            </a>
            <a href="javascript:void(0)" class="arrow-img right" @click="next()">
                <v-lazy-image src="/assets/images/right-arrow.png" alt="Logo" title="right-arrow" />
            </a>
        </div>
        <div class="row m-2" v-if="Object.keys(perItem).length">
            <!-- <div class="col m-0 p-0 position-relative" style="max-width: 250px;">
                <router-link class="d-flex justify-content-center" :to="{ path:'/', query:{'ref':$route.query.ref, 'utm_source':$route.query.utm_source, 'utm_campaign':$route.query.utm_campaign, 'utm_medium':$route.query.utm_medium} }" target="_blank">
                    <v-lazy-image width="180" height="40" src="/assets/images/logo.svg" alt="Logo" title="ZulluTrade" />
                </router-link>
                <select v-model="charSelected" class="d-block">
                    <option v-for="item,key in chartTypes" :key="key" :value="key">{{item.name}}</option>
                </select>
                <div id="traderprofitChart" class="profit" style="width: 250px; height: 300px; display: flex;"></div>
                <Loader :classname="'innerLoader'" v-if="chartLoader"></Loader>
                <p class="text-center mt-2">*TRADING INVOLVES HIGH RISK</p>
            </div> -->
            <div class="col m-0 p-0" style="max-width: 250px;" v-if="perItem.profile">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start marginRight">
                            <span class="circleImg me-1">
                                <v-lazy-image
                                    width="250"
                                    :src="static_vars.imagesURL+'webservices/Image.ashx?type=user&size=XL&id='+perItem.profile.zuluAccountId+'&ignore=false'"
                                    :alt="perItem.profile.name"
                                    :title="perItem.profile.name"
                                />
                            </span>
                            <h1 style="font-size: 20px;">{{ perItem.profile.name }}</h1>
                        </div>
                        <div class="title">
                            <div class="element d-flex justify-content-between mb-1">
                                <div class="element" v-if="perItem.overallStats.followers">
                                    <span class="moregray f-12 mb-0 me-1 gray">Amount Following</span>
                                    <strong class="d-block" style="font-size: 20px;color: var(--secondary);font-weight: bold;">
                                        {{ perItem.profile.baseCurrencySymbol }}{{ loc(Math.round(perItem.overallStats.amountFollowing || 0)) }}
                                    </strong>
                                </div>
                                <div class="element" v-if="perItem.overallStats && perItem.overallStats.includedInWatchlist">
                                    <span class="moregray f-12 mb-0 me-1 gray">Annualized ROI</span>
                                    <strong class="text-end m-0 d-block green" style="font-size: 20px;font-weight: bold;">
                                        {{ loc(Math.round(timeFrame.annualizedRorBasedRoi || 0)) }}%
                                    </strong>
                                </div>
                            </div>
                            <div class="element f-12">
                                <p class="d-flex justify-content-between f-12">
                                    <span class="gray">Profit: </span> <strong class="green">{{perItem.profile.baseCurrencySymbol}}{{ loc(timeFrame2.totalProfitMoney || 0) }}</strong>
                                </p>
                               <!--  <p class="d-flex justify-content-between f-12">
                                    <span class="gray">Weeks: </span> <strong>{{ loc(perItem.overallStats.weeks || 0) }}</strong>
                                </p>
                                <p class="d-flex justify-content-between f-12">
                                    <span class="gray">Avg Profit: </span> <strong>{{timeFrame2.profitPercentage?loc(timeFrame2.profitPercentage.toFixed(2) || 0):0}}%</strong>
                                </p>
                                <p class="d-flex justify-content-between f-12">
                                    <span class="gray">Maximum Drawdown: </span> <strong>-{{loc(timeFrame2.overallDrawDownMoneyPercent || 0)}}%</strong>
                                </p>
                                <p class="d-flex justify-content-between f-12">
                                    <span class="gray">Winning trades: </span> <strong>{{loc(timeFrame2.winTradesCount || 0)}} ({{loc(timeFrame2.winTrades || 0)}}%)</strong>
                                </p>
                                <p class="d-flex justify-content-between f-12">
                                    <span class="gray">Max Open Trades: </span> <strong>{{loc(timeFrame2.maxOpenTrades || 0)}}</strong>
                                </p>
                                <p class="d-flex justify-content-between f-12">
                                    <span class="gray">Investors: </span> <strong>{{ loc(perItem.overallStats.followers || 0) }}</strong>
                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="oneLine">
                    <ul class="loginRegister mt-2">
                        <li class="d-flex justify-content-around">
                            <a :href="{ path:'/trader/'+userID+'/trading', query:{'ref':$route.query.ref, 'utm_source':$route.query.utm_source, 'utm_campaign':$route.query.utm_campaign, 'utm_medium':$route.query.utm_medium,'t':Object.keys(perItem.timeframeStats)[0] ,'m':1} }" class="" style="border-radius: 15rem; padding: 4px 4rem;  background-color: #ff7200; border: 1px solid #ff7200; color: #ffffff;font-size: 13px;" target="_blank">
                                follow
                            </a>
                            <!-- <router-link :to="{ path:'/login', query:{'ref':$route.query.ref, 'utm_source':$route.query.utm_source, 'utm_campaign':$route.query.utm_campaign, 'utm_medium':$route.query.utm_medium} }" class="button mobLogin d-flex align-items-center fillBtn linkBtn" target="_blank">
                                login
                            </router-link> -->
                        </li>
                    </ul>
                    <p class="text-center f-12 mt-2">*TRADING INVOLVES HIGH RISK</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        return { store : myStore() };
    },
    data() {
        return {
            details:{},
            userID:'',
            traderList:{},
            traderIdList:[],
            chartLoader: false,
            chartTypes:{
                1:{ name:'Profit', params: { 'type': 'TOTAL_PROFIT_MONEY', 'timespan': 'DAILY', 'timeframe': '10000'}},
                2:{ name:'Monthly Profit', params: { 'type': 'TOTAL_PROFIT_MONEY', 'timespan': 'DAILY', 'timeframe': '30'}},
                3:{ name:'Pairs', params: { 'type': 'CURRENCY_COUNT', 'timespan': 'STATS', 'timeframe': '10000'}}
            },
            charSelected: 1,
            chartData:{}
        };
    },
    watch:{
        // userID(id){
        //     this.calltraderDetail(id);
        //     this.getChartData(id);
        // },
        charSelected(){
            this.getChartData(this.userID);
        }
    },
    computed:{
		perItem(){
			// return (((this.store.topTrdaersList || [])[0] || {}).result || [])[0]
            return this.traderList[this.userID] || {}
		},
        timeFrame(){
            try{
                return this.perItem.timeframeStats[Math.max(...Object.keys(this.perItem.timeframeStats))]
            }catch(e){ return {} }
        },
        timeFrame2(){
            let d = ((this.details[this.userID] || {}).stats || {}).timeframeStats
            if(d && Object.keys(d).length > 0){
                return d[Math.max(...Object.keys(d))]
            }else{
                return {}
            }
        }
    },
    methods: {
        prev(){
            if(!isNaN(this.$route.query.size) && this.$route.query.size > 1){
                let list = this.traderIdList.slice(0, this.$route.query.size)
                let i = list.indexOf(this.userID)
                if((i-1) >= 0){
                    this.userID = list[i-1]
                }else{
                    this.userID = list[list.length-1]
                }
            }
        },
        next(){
            if(!isNaN(this.$route.query.size) && this.$route.query.size > 1){
                let list = this.traderIdList.slice(0, this.$route.query.size)
                let i = list.indexOf(this.userID)
                if((i+1) <= (list.length-1)){
                    this.userID = list[i+1]
                }else{
                    this.userID = list[0]
                }
            }
        },
        loc(v){
            let num = new Number(v)
            return num.toLocaleString();
        },
		getChartData(id){
            this.chartLoader = true
            if(this.chartData[id] && this.chartData[id][this.charSelected]){
                this.chartLoader = false
                if(this.charSelected == 3){
                    this.drawPieChart()
                }else{
                    this.drawChart()
                }
            }else{
                this.store.allChartData(this.chartTypes[this.charSelected].params,true,id).then(response =>{
                    if(response.series && response.series[0]){
                        if(!this.chartData[id]){ this.chartData[id] = { 1:null, 2:null, 3:null }; }
                        this.chartData[id][this.charSelected] = response.series[0]
                    }
                    this.chartLoader = false
                    if(this.charSelected == 3){
                        this.drawPieChart()
                    }else{
                        this.drawChart()
                    }
                })
            }
        },
        getAllTradersList() {
            let formData = {
                "flavor": "eu",
                "hasLiveUsersSubscribed": true,
                "page": 0,
                "size": this.$route.query.size || 4,
                "sortAsc": false,
                "sortBy": "amountFollowing",
                "timeFrame": 10000,
                "tradedWithinLastWeek": true,
                "bringDetailedChart": true,
                "accessingFlavorId": 1
            }
            this.store.callAllTradersList(formData, true,'', this).then(res =>{
                if(Object.keys(res).length && res.result && res.result.length){
                    res.result.forEach(item=>{
                        this.traderList[item.trader.providerId] = item.trader
                        this.traderIdList.push(item.trader.providerId)
                     })
                    this.userID = this.traderIdList[0]
                }
            })
        },
        calltraderDetail(id){
            if(this.details[id]){ return }
			this.store.calltraderDetail({}, true,id,this).then((res) => {
				this.details[id] = res.trader
			});
		},
        drawChart(){
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === "traderprofitChart");
            d?.dispose();
            let root = am5.Root.new("traderprofitChart");

            root.interfaceColors.set("grid", am5.color(0xffffff));

            root.setThemes([am5themes_Animated.new(root)]);

            root.dateFormatter.setAll({
                dateFormat: "dd MMM yyyy",
                dateFields: ["valueX"],
            });
            root.numberFormatter.setAll({
                numberFormat: "#.##",
                smallNumberThreshold: 0.001,
            });
            // Create drawprofitChart
            var chart = root.container.children.push(
                am5xy.XYChart.new(root, { focusable: true, paddingRight: 0, paddingLeft: 0 })
            );
            var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                    maxDeviation: 0.5,
                    visible: false,
                    baseInterval: {
                        timeUnit: "day",
                        count: 1
                    },
                    renderer: am5xy.AxisRendererX.new(root, {
                    pan:"zoom"
                }),
                // tooltip: am5.Tooltip.new(root, {})
            }));

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    visible: false,
                    renderer: am5xy.AxisRendererY.new(root, {}),
                })
            );
            let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({ disabled : true });
            let chartData = this.chartDataProfit()
            if (chartData.length > 1) {
                chart.get("colors").set("colors", [am5.color(0xffdfc3), am5.color(0xff7200)]);
            } else {
                chart.get("colors").set("colors", [am5.color(0xff7200)]);
            }
            chartData.forEach((key) => {
                // Add series
                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        // legendLabelText: "Profit (" + this.perItem.profile.baseCurrencyName + ")",
                        minBulletDistance: 1,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        seriesTooltipTarget: "bullet",
                        tooltip: am5.Tooltip.new(root, {
                            getFillFromSprite: false,
                            getStrokeFromSprite: true,
                            autoTextColor: true,
                            getLabelFillFromSprite: true,
                            pointerOrientation: "horizontal",
                            scale:0.7,
                            /*stroke: am5.color(0xff5566),
                            strokeOpacity: 0.8,*/
                            fill: am5.color(0xffffff),
                            fillOpacity: 0,
                            labelText: `${key.text?(key.text+'\n'):''}[bold]${key.name} (${this.perItem.profile.baseCurrencyName}):[/] {valueY}\n [bold]Date:[/] {valueX}`,
                        }),
                    })
                );
                series.fills.template.setAll({ visible: true });

                series.fills.template.set(
                    "fillGradient",
                    am5.LinearGradient.new(root, {
                        stops: [ { opacity: 0.5 }, { opacity: 0.2 }, ],
                    })
                );
                var data = key.data;
                series.data.setAll(data);
                series.appear(100);
            });

            // Add cursor
            let cursor = chart.set(
                "cursor",
                am5xy.XYCursor.new(root, {
                    // xAxis: xAxis,
                    behavior: "zoomX",
                })
            );
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            //chart.zoomOutButton.set("forceHidden", true);

            /*xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 10,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });
            var label2 = am5.Label.new(root, {
                text: "[#666] Date [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            });
            xAxis.children.push(label2);*/
            chart.appear(1000, 100);
        },
        drawPieChart(){
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'traderprofitChart');
            d?.dispose();
            var root = am5.Root.new("traderprofitChart");
            root.setThemes([
                am5themes_Animated.new(root),
                am5themes_Responsive.new(root)
            ]);

            // Create chart
            var chart = root.container.children.push(am5percent.PieChart.new(root, {
                layout: root.verticalLayout
            }));

            var series = chart.series.push(am5percent.PieSeries.new(root, {
                valueField: "y",
                categoryField: "x",
            }));
            series.get("colors").set("colors", [
                am5.color(0x4572a7),
                am5.color(0xCBCBCB),
                am5.color(0x69cd4b),
                am5.color(0xff6f34),
                am5.color(0xf6f819),
                am5.color(0x7dfe8b),
                am5.color(0xffaf6e),
                am5.color(0xfff263),
                am5.color(0xff7200),
            ]);
            series.data.setAll(this.chartData[this.userID][this.charSelected].data);
            series.slices.template.set("tooltipText", "{x}: {y}");
            series.slices.template.set("toggleKey", "none");
            series.labels.template.setAll({
                text: "{x}",
                radius: 10,
                fontSize: 12,
                fontWeight: "600",
            });
            series.appear(1000, 100);
        },
        chartDataProfit(){
            let chartData = this.chartData[this.userID][this.charSelected]
            var index = chartData.endDateOfImportedTradeHistory?chartData.data.findIndex(ind => ind.x > chartData.endDateOfImportedTradeHistory):-1;
            var finaldata = [];
            if(index != -1){
                finaldata.push({ data: chartData.data.slice(0, index), name: "Imported history Profit", text:'Performance using Imported history' });
                finaldata.push({ data: chartData.data.slice(index - 1), name: "Live Profit", text:'Performance using Live account' });
            }else{
                finaldata.push({ data: chartData.data, name: "Live Profit" });
            }
            return finaldata;
        },

    },
    created() {
        this.getAllTradersList();
    }
}
</script>
<style scoped>
    p{ margin-bottom: 0.5rem !important; }
    .gray{ color: #959595; font-size: 12px; font-weight: 600; }
    .arrow-img{
        top: calc(50% - 29px);
        z-index: 10;
        position: absolute;
    }
    .arrow-img a {
        cursor: pointer;
        background-color:transparent;
    }
    a.arrow-img.left { left: 0; }
    a.arrow-img.right { right: 0; }
</style>